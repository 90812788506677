import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import PromoFormComponent from '@promo-platform/promo-form';

import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { ImageWithPromoFormProps } from './model';

import '@promo-platform/styles';
import './ImageWithPromoForm.scss';

const ImageWithPromoForm: FC<ImageWithPromoFormProps> = ({ formTitle, formId, formUrl, image }) => (
  <div className="image-with-form">
    <div className="image-with-form__container">
      <GatsbyImage
        className="image-with-form__image"
        image={image[0].properties.image}
        alt={image[0].properties.imageAlt}
        objectFit="contain"
      />
      <div
        className={classNames('image-with-form__form', {
          'image-with-form__form--with-cms-title': formTitle,
        })}
      >
        <DangerouslySetInnerHtml className="image-with-form__form-title" html={formTitle} />
        <PromoFormComponent id={formId} url={formUrl} />
      </div>
    </div>
  </div>
);

export const query = graphql`
  fragment FragmentImageWithPromoForm on TImageWithPromoForm {
    structure
    properties {
      formTitle
      formId
      formUrl
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 455) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
    }
  }
`;

export default ImageWithPromoForm;
