import React, { FC, useCallback } from 'react';

import Button from 'common/Button';
import { DangerouslySetInnerHtml } from 'layout';
import CheckboxField from 'common/CheckboxField/CheckboxField';

import { handleChangeFormData } from '../actions';
import { IForm } from '../model';

const Form: FC<IForm> = ({
  description,
  button,
  ariaButton,
  title,
  labelEmail,
  labelFirstName,
  labelLastName,
  labelCheckbox,
  labelPhone,
  phonePlaceholder,
  phonePattern,
  setFormData,
  handleSubmit,
  formData,
  formState,
  disclaimer,
  errorMessage,
  setFormState,
  collapsibleCheckboxText,
  additionalLabelCheckbox,
  additionalCollapsibleCheckboxText,
  extraLabelCheckbox,
  personalizeAdvertising,
  isCheckboxRequired,
  signupType,
}) => {
  const { isSending, isError } = formState;

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeFormData(event, setFormData, setFormState);
  }, []);

  return (
    <div className="signup__info">
      <DangerouslySetInnerHtml element="h2" className="signup__title" html={title} />
      <DangerouslySetInnerHtml className="signup__description" html={description} />
      <form className="signup__form" action="#" onSubmit={handleSubmit}>
        {labelFirstName ? (
          <div className="signup__form-group signup__form-group--first">
            <label htmlFor="first_name">{labelFirstName}</label>
            <input
              onChange={handleChange}
              value={formData.first_name}
              name="first_name"
              id="first_name"
              data-testid="first_name"
              type="text"
              required
            />
          </div>
        ) : null}
        {labelLastName && (
          <div className="signup__form-group signup__form-group--last">
            <label htmlFor="last_name">{labelLastName}</label>
            <input
              onChange={handleChange}
              value={formData.lastName}
              name="lastName"
              id="last_name"
              data-testid="last_name"
              type="text"
              required
            />
          </div>
        )}
        <div className="signup__form-group signup__form-group--email">
          {!signupType ? <label htmlFor="email">{labelEmail}</label> : null}
          <input
            onChange={handleChange}
            value={formData.email}
            name="email"
            id="email"
            data-testid="email"
            type="email"
            placeholder={signupType ? labelEmail : ''}
            required
          />
        </div>
        {labelPhone && (
          <div className="signup__form-group signup__form-group--phone">
            <label htmlFor="phone">{labelPhone}</label>
            <input
              onChange={handleChange}
              value={formData.phone}
              name="phone"
              id="phone"
              data-testid="phone"
              type="tel"
              placeholder={phonePlaceholder || null}
              pattern={phonePattern || null}
              required
            />
          </div>
        )}
        <div className="signup__form-group--rest">
          {isError && !!errorMessage && (
            // eslint-disable-next-line react/no-unknown-property
            <p className="signup__error" aria-errormessage={errorMessage} live-region="alert">
              {errorMessage}
            </p>
          )}
          <DangerouslySetInnerHtml
            html={personalizeAdvertising}
            className="signup__personalize-adv"
          />
          {extraLabelCheckbox ? (
            <CheckboxField
              id="signup-form-extraPolicy"
              name="extraPolicy"
              handleChange={handleChange}
              value={formData.extraPolicy}
              isRequired={isCheckboxRequired}
              labelText={extraLabelCheckbox}
            />
          ) : null}
          {labelCheckbox ? (
            <CheckboxField
              id="signup-form-policy"
              name="policy"
              handleChange={handleChange}
              value={formData.policy}
              isRequired={isCheckboxRequired}
              labelText={labelCheckbox}
              collapsedText={collapsibleCheckboxText}
            />
          ) : null}
          {additionalLabelCheckbox ? (
            <CheckboxField
              id="signup-form-additionalPolicy"
              name="additionalPolicy"
              handleChange={handleChange}
              value={formData.additionalPolicy}
              isRequired={isCheckboxRequired}
              labelText={additionalLabelCheckbox}
              collapsedText={additionalCollapsibleCheckboxText}
            />
          ) : null}
          <DangerouslySetInnerHtml className="signup__disclaimer" html={disclaimer} />
          <Button
            disabled={isSending}
            ariaLabel={ariaButton}
            type="submit"
            variant="primary"
            isLoading={isSending}
          >
            {button}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Form;
